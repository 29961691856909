import axios from 'axios';
import Globals from "./globals";

// https://dev.to/teroauralinna/global-http-request-and-response-handling-with-the-axios-interceptor-30ae
// https://codepen.io/teroauralinna/pen/vPvKWe?editors=0010

let handlerEnabled = false;

const requestHandler = (request) => {
    if (handlerEnabled) {
        console.log('Request: ' + request.url + ' ' + JSON.stringify(request.data));
    }

    // add the bearer token to the request header
    request.headers.Authorization = 'Bearer ' + Globals.userInfo.bearer

    return request;
};

const errorHandler = (error) => {
    if (handlerEnabled) {
        console.log('Error: ' + error.response.status + ' ' + error.config.url);
    }
    return Promise.reject({ ...error });
};

const successHandler = (response) => {
    if (handlerEnabled) {
        console.log('Success: ' + response.config.url);
    }
    return response;
};

const axiosInstance = axios.create({
    baseURL: window.api,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': ''
    }
});

axiosInstance.interceptors.request.use(
    request => requestHandler(request)
);

axiosInstance.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
);


export default axiosInstance;
