import React, { Component } from 'react';
import { Route, withRouter, Redirect, Switch } from 'react-router';
import { Layout } from './components/Layout';
import Globals from '../src/config/globals';
import Login from './components/Auth/Login';
import MembersHome from './components/Member/MembersHome';
import MemberInfo from './components/Member/MemberInfo';
import MemberInfoSlider from './components/Member/MemberInfoSlider';

class App extends Component {

    constructor(props) {
        super(props);

        //if (window.sessionStorage.getItem("userInfo")) {
        //    Globals.userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
        //}

        //if (window.sessionStorage.getItem("associatedFacilities")) {
        //    Globals.associatedFacilities = JSON.parse(window.sessionStorage.getItem("associatedFacilities"));
        //}
    }

    componentDidMount() {
        //Modal.setAppElement('#root');

        //console.log(Globals.selectedFacility);
    }

    updateMembersLoading = () => {
        this.setState({
            loadingMembers: Globals.loadingMembers
        });
    }

    updateMembers = () => {
        this.setState({
            membersLoaded: true,
            loadingMembers: false
        });
        Globals.loadingMembers = false;

        console.log(Globals.members);
    }

    render() {
        console.log(this.props);
        return (
            <div className="nav-function-top">
                {
                    this.props.location.pathname === '/' ? (
                        <div>
                            <Route exact path='/' component={Login} />
                        </div>
                    ) : (
                            <Layout updateMembersLoading={this.updateMembersLoading} updateMembers={this.updateMembers}>
                                <Route path='/members' render={(props) => <MembersHome />} />
                                <Route path='/member-info' render={(props) => <MemberInfo />} />
                            </Layout>)
                }
            </div>
        );
    }
}

export default withRouter(App);
