import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Axios from '../../config/axios';
import Globals from '../../config/globals';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { ColumnToggle, Search } from 'react-bootstrap-table2-toolkit';
import Moment from 'moment';
import $ from 'jquery';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Fragment } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import { smallBox, SmartMessageBox } from "../../common/utils/functions";
//import AddMemberPane from './AddMemberPane';
import MemberInfoSlider from './MemberInfoSlider';
import MemberDuesSlider from './MemberDuesSlider';
import ElectricBoatSlider from './ElectricBoatSlider';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { toast, confirm } from '@rickylandino/react-messages';

class MembersHome extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        console.log('In MembersHome.getDerivedStateFromProps');
        if (Globals.loadingMembers && nextProps.loadingMembers && (nextProps.loadingMembers !== prevState.loading)) {
            return {
                loading: nextProps.loadingMembers ? true : false
            }
        }

        if (Globals.members.length > 0) {
            return {
                members: Globals.members,
                loading: false,
                noMembers: false
            }
        }
        else {
            //console.log('Globals.members');
            //console.log(Globals.members);
            //this.GetAllMembers();
        }
        console.log(nextProps);
        
        return null;
    }

    constructor(props) {
        super(props);

        console.log('In MembersHome.constructor');

        this.state = {
            members: [],
            selectedMember: null,
            loading: false,
            loadMember: true,
            formFields: {},
            showAddMember: false,
            showMemberInfoSlider: false,
            showMemberDuesSlider: false,
            showElectricBoatSlider: false,
            noMembers: true
        }
        this._isMounted = false;
        this.handleClick = this.handleClick.bind(this);
        //this.GetAllMembers = this.GetAllMembers.bind(this);
    }

    componentDidMount() {
        console.log(Globals.userInfo.username);

        this._isMounted = true;
        this.GetAllMembers();
        $(document).ready();
        $(document.body).on('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        this._isMounted = false;
        $(document.body).off('keydown', this.handleKeyDown);
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log('In MembersHome.componentDidUpdate');
        //if (this.state.members.length > 0) {
        //    this.GetAllMembers();
        //}
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;


        this.setState({ formFields: { ...this.state.formFields, [name]: value } }, () => console.log(this.state));
    }

    handleKeyDown = (event) => {
        if (event.keyCode == 13 /*enter*/) {
            if (!event.target.offsetParent === 'div.search-div.dropdown') {
                this.selectMember(this.state.selectedMember);
            }           
        }
        if (event.keyCode == 40 /*esc*/) {
            let i = this.state.members.findIndex(obj => obj.MemberModel.MemberID === this.state.selectedMember.MemberModel.MemberID);
            let selectedMember = this.state.members[i + 1]
            this.setState({
                selectedMember
            });
        }

        if (event.keyCode == 38 /*esc*/) {
            let i = this.state.members.findIndex(obj => obj.MemberModel.MemberID === this.state.selectedMember.MemberModel.MemberID);
            if (i !== 0) {
                let selectedMember = this.state.members[i - 1]
                this.setState({
                    selectedMember
                });
            }
        }
    }

    handleClick(event) {
        event.preventDefault();

        switch (event.target.id) {
            case 'backToResults':

                this.setState({
                    selectedMember: null,
                    loading: true,
                    loadMember: true
                }, () => this.GetAllMembers());
                break;
            default:
                break;
        }
    }

    handleResetSearch = () => {
        //props.onSearch("");
        //this.GetAllMembers();
    }

    handleAddNewMember = () => {
        this.setState({
            memberSSN: null,
            showMemberInfoSlider: true,
            selectedIdx: null
        });
    }

    handleElectricBoatPayment = () => {
        this.setState({
            showElectricBoatSlider: true
        });
    }

    GetAllMembers = () => {
        const t0 = performance.now();
        
        let postdata = {
                
        };

        Axios.post(`/api/GetAllMembers`, postdata
        ).then(response => {
            //const t1 = performance.now(); console.log(`Call to doSomething took ${t1 - t0} milliseconds.`);

            Globals.searchCriteria = 'All Members Displayed'
            Globals.members = response.data;
            console.log(Globals.members);
            this.setState({
                members: response.data,
                selectedMember: response.data.length > 0 && response.data[0],
                loading: false
            }, () => console.log(this.state.members));
        }).catch(error => {
            console.log(error);
        });   
    }

    updateMembersLoading = () => {
        this.setState({
            loading: true
        });
    }

    updateMembers = (members) => {
        //console.log('here in updateMembers');
        console.log(members);

        Globals.members = members;

        //React Table Refresh hack
        this.setState({
            noMembers: true
        }, () => {
            this.setState({
                members: Globals.members,
                loading: false,
                noMembers: members.length > 0 ? false : true
            })
        });
    }

    handleDeleteMember = (ssno) => {
        confirm({
            title: "You are about to permanently delete this Member",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {

                let postdata = {
                    ss_no: ssno
                };

                Axios.post(`/api/DeleteMember`, postdata
                ).then(response => {
                    if (response.data) {
                        this.updateMembers(response.data);

                        console.log('Member Deleted');

                        toast.success("Member has been deleted");
                    }
                }).catch(error => {
                    console.log(error);
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    render() {
        const { SearchBar } = Search;

        function fullNameFormatter(cell, row) {
            return (
                <span>{row.providerModel && (row.providerModel.fName + ' ' + row.providerModel.lName)}</span>
            );
        }

        function checkboxFormatter(cell, row) {
            return (
                <div className="">
                    <div className="custom-control custom-checkbox custom-control-inline">
                        {cell === 1 &&
                            <i className="fas fa-check fa-125x color-pink text-center"></i>
                        }
                    </div>
                </div>
            );
        }

        function folderFormatter(cell, row) {
            return (
                <i className="far fa-folder-open fa-125x color-pink text-center"></i>
            );
        }

        const handleInputChange = (e) => {
            this.handleInputChange(e);
        }

        const addToWorklist = () => {
            this.addToWorklist();
        }

        const emptyFormFields = () => {
            let formFields = {
                modality: ''
            };
            this.setState({
                formFields
            });
        }

        function printFormatter(cell, row) {
            return (
                <i className="far fa-print fa-125x color-pink"></i>
            );
        }

        function mInfoFormatter(cell, row) {
            return (
                <i id="openMember" className="fa fa-125x fa-user text-icon" />
            );
        }

        function mDuesFormatter(cell, row) {
            return (
                <i id="openDues" className="fa fa-125x fa-credit-card text-icon" />
            );
        }

        function deleteFormatter(cell, row) {
            return (
                <i id="deleteMember" className="far fa-trash-alt fa-125x hover text-danger"></i>
            );
        }

        const { ToggleList } = ColumnToggle;
        const columns = [{
            dataField: 'memberModel.lname',
            text: 'Last Name'
        }, {
            dataField: 'memberModel.fname',
            text: 'First Name'
        }, {
            dataField: 'memberModel.sS_NO',
            text: 'SSN #'
        }, {
            dataField: 'memberModel.clock',
            text: 'Clock #'
        }, {
            dataField: 'dum1',
            isDummyField: true,
            text: 'Member Info',
            formatter: mInfoFormatter,
            style: { textAlign: 'center' },
            headerStyle: { width: '150px' }
        }, {
            dataField: 'dum2',
            isDummyField: true,
            text: 'Member Dues',
            formatter: mDuesFormatter,
            style: { textAlign: 'center' },
            headerStyle: { width: '150px' }
        }, {
            dataField: 'dum3',
            isDummyField: true,
            text: 'Delete Member',
            formatter: deleteFormatter,
            style: { textAlign: 'center' },
            headerStyle: { width: '150px' }
        }];

        const columnsMobile = [{
            dataField: 'memberModel.lname',
            text: 'Last Name'
        }, {
            dataField: 'memberModel.fname',
            text: 'First Name'
        }];

        const pagination = {
            sizePerPage: 10,
            pageStartIndex: 1,
            alwaysShowAllBtns: true,
            sizePerPageList: [{ text: "5", value: 5 }, { text: "10", value: 10 }, { text: "50", value: 50 }, { text: "100", value: 100 }], // A numeric array is also available. the purpose of above example is custom the text
            showTotal: true
        };

        const paginationMobile = {
            sizePerPage: 10,
            pageStartIndex: 1,
            alwaysShowAllBtns: true,
            showTotal: false
        };

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            hideSelectColumn: true,
            style: { background: 'pink' },
            selected: [this.state.selectedMember && this.state.selectedMember.memberModel.sS_NO]
        };


        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                if (e.target.tagName === 'I' && e.target.id === 'openMember') {
                    this.setState({
                        selectedMember: row,
                        memberSSN: row.memberModel.sS_NO,
                        showMemberInfoSlider: true,
                        selectedIdx: rowIndex
                    }, () => console.log(this.state.memberSSN));
                }
                else if (e.target.tagName === 'I' && e.target.id === 'openDues') {
                    this.setState({
                        selectedMember: row,
                        memberSSN: row.memberModel.sS_NO,
                        memberName: row.memberModel.fname + ' ' + row.memberModel.lname,
                        showMemberDuesSlider: true,
                        selectedIdx: rowIndex
                    }, () => console.log(this.state.memberName));
                }
                else if (e.target.tagName === 'I' && e.target.id === 'deleteMember') {
                    this.handleDeleteMember(row.memberModel.sS_NO);

                    //console.log('Delete Member clicked');
                    //console.log(row.memberModel.sS_NO);
                }
                else {
                    this.setState({
                        selectedMember: row,
                        memberSSN: row.memberModel.sS_NO,
                        memberName: row.memberModel.fname + ' ' + row.memberModel.lname,
                        selectedIdx: rowIndex
                    }, () => console.log(this.state.memberName));
                }
            }
        };

        //const expandRow = {
        //    renderer: row => (
        //        <div>
        //            <div><strong>Name: </strong>{row.MemberModel.fName} + {row.MemberModel.lName}</div>
        //            <div><strong>DOB: </strong>{row.MemberModel.dob}</div>
        //            <div><strong>MRN #: </strong>{row.MemberModel.mrn}</div>
        //            <div><strong>Address: </strong>{row.MemberModel.address1}</div>
        //            <div><strong>Referring MD: </strong>{row.MemberModel.primaryCarePhysician}</div>
        //        </div>
        //    ),
        //    showExpandColumn: true
        //};

        //console.log(this.state.members);

        return (
            <div>
                <div className="row">
                    <div className="col-lg-12" >
                        <div className="panel-hdr">
                            <h2>
                                MEMBER INFO
                            </h2>

                            <button type="button" className="btn btn-submit margin-0-10" onClick={this.handleAddNewMember}>
                                New Member
                            </button>
                            <button type="button" className="pinkTextA btn btn-outline-default margin-0-10">
                                Print List
                            </button>
                            <button type="button" className="pinkTextA btn btn-outline-default margin-0-10">
                                Export List
                            </button>
                            <button type="button" className="pinkTextA btn btn-outline-default margin-0-10" onClick={this.handleElectricBoatPayment}>
                                Process Electric Boat Payment
                            </button>
                        </div>
                        <Fragment>
                                <div className="frame-wrap">
                                    {this.state.loading ?
                                        <span className="frame-heading"><ReactLoading className='tableLoading' type={"spokes"} color={'#F30079'} height={30} width={30} /> Loading ...</span>
                                        :
                                    <div>
                                        {this.state.noMembers ?
                                            <div>No Members found</div>
                                            :
                                            <Fragment>
                                                <br />
                                                <h4>
                                                    <div>
                                                        Use the Search Box below to filter members
                                                        {/* Search Criteria: {Globals.searchCriteria}&nbsp;&nbsp;&nbsp;*/}
                                                        {/* <button type="button" className="pinkTextA btn btn-outline-default margin-0-10" onClick={this.handleResetSearch}>*/}
                                                        {/*    <i className="fas fa-undo fa-1x" id="reportsBtn" />&nbsp;Reset Search*/}
                                                        {/*</button>*/}
                                                    </div>
                                                </h4>
                                                
                                            <div className="fullTable">
                                                <ToolkitProvider
                                                    keyField='memberModel.sS_NO'
                                                    data={this.state.members}
                                                    columns={columns}
                                                    columnToggle
                                                    search
                                                >
                                                    {
                                                        props => (
                                                            <div>
                                                                <SearchBar {...props.searchProps} />
                                                                <BootstrapTable
                                                                    pagination={paginationFactory(pagination)}
                                                                    {...props.baseProps}
                                                                    rowEvents={rowEvents}
                                                                    selectRow={selectRow}
                                                                    hover condensed />
                                                            </div>
                                                        )
                                                    }
                                                </ToolkitProvider>
                                            </div>
                                            <div className="mobileTable">
                                                <BootstrapTable keyField='memberModel.sS_NO'
                                                    data={this.state.members}
                                                    columns={columnsMobile}
                                                    pagination={paginationFactory(paginationMobile)}
                                                    rowEvents={rowEvents}
                                                    hover condensed />
                                                </div>
                                            </Fragment>
                                            }
                                        </div>
                                    }
                                </div>
                        </Fragment>
                    </div>
                </div>

                <MemberInfoSlider showPane={this.state.showMemberInfoSlider} memberSSN={this.state.memberSSN} members={this.state.members} selectedIdx={this.state.selectedIdx} updateMembers={(members) => this.updateMembers(members)} hidePane={() => this.setState({ showMemberInfoSlider: false })} {...this.props} />
                <MemberDuesSlider showPane={this.state.showMemberDuesSlider} memberSSN={this.state.memberSSN} memberName={this.state.memberName} hidePane={() => this.setState({ showMemberDuesSlider: false })} {...this.props} />
                <ElectricBoatSlider showPane={this.state.showElectricBoatSlider} hidePane={() => this.setState({ showElectricBoatSlider: false })} {...this.props} />
            </div>
        );
    }
}

export default withRouter(MembersHome);