import React, { Component, Fragment } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { withRouter } from "react-router-dom";

import Axios from '../../config/axios';
import Globals from '../../config/globals';
import Moment from 'moment';
import { Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { ColumnToggle } from 'react-bootstrap-table2-toolkit';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from 'react-bootstrap/Card';
import '../../css/card.css';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import ReactLoading from 'react-loading';
import { smallBox, SmartMessageBox } from "../../common/utils/functions";
import $ from 'jquery';

class ElectricBoatSlider extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        
        if (nextProps.showPane === true && nextProps.showPane !== prevState.showPane) {
            return nextProps.showPane === true ? { showPane: true, loading: true } : { showPane: false };
        }
        
        return null;
    }

    constructor(props) {
        super(props);

        console.log('In Constructor');

        this.state = {
            showPane: false,
            ebMembers: {},
            loading: true,
            formFields: {},
            selectedMember: null,
        }

        this.isDirty = false;
        this.checkOnDirty = true;
        this.popEventListnerAdded = false;
        this.allowModal = true;

        this.refreshData = this.refreshData.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.showPane !== this.state.showPane && this.state.showPane === true) {
            this.setState({
                loading: false,
            });

            this.refreshData();
        }
    }

    componentDidMount() {

    }

    hidePane = () => {
        this.setState({
            showPane: false
        });

        this.props.hidePane();
    }

    refreshData() {
        if (this.state.memberSSN !== null) {
            let postdata = {

            };

            Axios.post(`/api/GetAllElectricBoatMembers`, postdata
            ).then(response => {
                this.setState({
                    ebMembers: response.data,
                    selectedMember: response.data.length > 0 && response.data[0],
                    loading: false
                }, () => console.log(this.state.selectedMember));
            }).catch(error => {
                console.log('here in err');
            });
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        //console.log(this.isDirty);
        console.log(name);
        console.log(value);

        this.setState({ formFields: { ...this.state.formFields, [name]: value } }, () => {
            console.log(this.state.formFields);
            //console.log(this.state.origFormFields);

            if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.state.origFormFields)) {
                //console.log('isDirty');
                this.isDirty = true;  // dirty
            }
        });
    }

    handleSubmit() {

    }

    render() {
        const { ToggleList } = ColumnToggle;
        const columns = [{
            dataField: 'memberModel.lname',
            text: 'Last Name'
        }, {
            dataField: 'memberModel.fname',
            text: 'First Name'
        }, {
            dataField: 'memberModel.sS_NO',
            text: 'SSN #'
        }, {
            dataField: 'memberModel.clock',
            text: 'Clock #'
        }];

        const columnsMobile = [{
            dataField: 'memberModel.lname',
            text: 'Last Name'
        }, {
            dataField: 'memberModel.fname',
            text: 'First Name'
        }];

        const pagination = {
            sizePerPage: 10,
            pageStartIndex: 1,
            alwaysShowAllBtns: true,
            sizePerPageList: [{ text: "5", value: 5 }, { text: "10", value: 10 }, { text: "50", value: 50 }, { text: "100", value: 100 }], // A numeric array is also available. the purpose of above example is custom the text
            showTotal: true
        };

        const paginationMobile = {
            sizePerPage: 10,
            pageStartIndex: 1,
            alwaysShowAllBtns: true,
            showTotal: false
        };

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            hideSelectColumn: true,
            style: { background: 'pink' },
            selected: [this.state.selectedMember && this.state.selectedMember.memberModel.sS_NO]
        };

        const rowEvents = {
            //onClick: (e, row) => {
            //    //this.selectDuesRow(row);
            //}
        };

        return (
            <SlidingPane
                className='some-custom-class'
                overlayClassName='showCard'
                isOpen={this.state.showPane}
                title='Process Electric Boat Payment'
                onRequestClose={this.hidePane}
            >
                <div className="slide-pane-body scrollableDiv">
                    <div>
                        {!this.state.loading &&
                            <Fragment>
                                <Card className="local777Card">
                                    <Card.Header><span>Current Electric Boat Members</span></Card.Header>
                                    <Card.Body>
                                        <form>
                                            <div className="row">
                                                <div className="frame-wrap">
                                                    {this.state.loading ?
                                                        <span className="frame-heading"><ReactLoading className='tableLoading' type={"spokes"} color={'#F30079'} height={30} width={30} /> Loading ...</span>
                                                        :
                                                        <div>
                                                            <Fragment>

                                                                <div className="fullTable">
                                                                    <ToolkitProvider
                                                                        keyField='memberModel.sS_NO'
                                                                        data={this.state.ebMembers}
                                                                        columns={columns}
                                                                        columnToggle
                                                                    >
                                                                        {
                                                                            props => (
                                                                                <div>
                                                                                    <BootstrapTable
                                                                                        pagination={paginationFactory(pagination)}
                                                                                        {...props.baseProps}
                                                                                        rowEvents={rowEvents}
                                                                                        selectRow={selectRow}
                                                                                        hover condensed />
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </ToolkitProvider>
                                                                </div>
                                                                <div className="mobileTable">
                                                                    <BootstrapTable keyField='memberModel.sS_NO'
                                                                        data={this.state.ebMembers}
                                                                        columns={columnsMobile}
                                                                        pagination={paginationFactory(paginationMobile)}
                                                                        rowEvents={rowEvents}
                                                                        hover condensed />
                                                                </div>
                                                            </Fragment>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </Card.Body>
                                </Card>
                            </Fragment>
                        }
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="form-group col-12 padding-25-10">
                        <button className="btn btn-outline-default" onClick={this.hidePane}>Return To All Members</button>
                    </div>
                </div>
            </SlidingPane>
        );
    }
}

export default withRouter(ElectricBoatSlider);