import React, { Component, Fragment } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { withRouter } from "react-router-dom";

import Axios from '../../config/axios';
import Globals from '../../config/globals';
import Moment from 'moment';
import { Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { ColumnToggle } from 'react-bootstrap-table2-toolkit';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from 'react-bootstrap/Card';
import '../../css/card.css';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import ReactLoading from 'react-loading';
import { smallBox, SmartMessageBox } from "../../common/utils/functions";
import $ from 'jquery';

class MemberDuesSlider extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        
        if (nextProps.showPane === true && nextProps.showPane !== prevState.showPane) {
            return nextProps.showPane === true ? { showPane: true, memberSSN: nextProps.memberSSN, memberName: nextProps.memberName, loading: true, slidePanelTitle: nextProps.slidePanelTitle } : { showPane: false };
        }
        
        return null;
    }

    constructor(props) {
        super(props);

        this.state = {
            showPane: false,
            memberDues: {},
            loading: true,
            memberName: '',
            memberSSN: '',
            selectedDuesRow: {},
            showDuesModal: false,
            editMode: 'add',
            formFields: {},
            btnModalSubmitClicked: false
        }

        this.isDirty = false;
        this.checkOnDirty = true;
        this.popEventListnerAdded = false;
        this.allowModal = true;

        this.refreshData = this.refreshData.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleAddDuesPayment = this.handleAddDuesPayment.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.showPane !== this.state.showPane && this.state.showPane === true) {
            this.setState({
                loading: false,
                slidePanelTitle: 'Member Dues'
            });
            this.refreshData();
        }
    }

    componentDidMount() {

    }

    hidePane = () => {
        this.setState({
            showPane: false
        });

        this.props.hidePane();
    }

    refreshData() {
        if (this.state.memberSSN !== null) {
            let postdata = {
                ss_no: this.state.memberSSN
            };

            Axios.post(`/api/GetMemberDuesInformationBySSN`, postdata
            ).then(response => {
                this.setState({
                    memberDues: response.data,
                    loading: false,
                    showDuesModal: false
                }, () => console.log(this.state.memberDues));
            }).catch(error => {
                console.log('here in err');
            });
        }
    }

    showToast(message) {
        if (message === 'Success') {
            this.notifySuccess();
        }
        else {
            this.notifyFail();
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        //console.log(this.isDirty);
        console.log(name);
        console.log(value);

        this.setState({ formFields: { ...this.state.formFields, [name]: value } }, () => {
            console.log(this.state.formFields);
            //console.log(this.state.origFormFields);

            if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.state.origFormFields)) {
                //console.log('isDirty');
                this.isDirty = true;  // dirty
            }
        });
    }

    handleDateChange = (name, date) => {
        this.setState({ formFields: { ...this.state.formFields, [name]: date } });
    }

    handleAddDuesPayment() {
        var today = new Date();
        var onemonthout = new Date();
        onemonthout.setMonth(onemonthout.getMonth() + 1);

        this.setState({
            editMode: 'add',
            showDuesModal: true,
            formFields: {
                ss_no: this.state.memberSSN,
                amt: '',
                fees: '',
                pD_DATE: today,
                pD_THRU: onemonthout,
                cK_CASH: '',
                cK_NO: ''
            },
            btnModalSubmitClicked: false
        });
    }

    selectDuesRow = (row) => {
        this.setState({
            editMode: 'edit',
            selectedDuesRow: row,
            showDuesModal: true,
            formFields: row
        });
    }

    handleSubmit() {
        let errMsg = '';

        console.log(this.state.formFields.cK_CASH);

        this.setState({
            btnModalSubmitClicked: true
        });

        let postdata = this.state.formFields;

        if (postdata.amt == '') { errMsg += 'Dues Amount<br />'; }
        if (postdata.fees == '') { errMsg += 'Fees<br />'; }
        if (postdata.pD_DATE == '' || postdata.pD_DATE == null) { errMsg += 'Date Paid<br />'; }
        if (postdata.pD_THRU == '' || postdata.pD_THRU == null) { errMsg += 'Paid Thru<br />'; }
        if (postdata.cK_CASH == '') { errMsg += 'Cash or Check<br />'; }

        if (errMsg != '') {
            smallBox({
                title: "Notice",
                content: "<i class='fa fa-clock-o'></i> Please make sure all required fields are filled in.<br />" + errMsg,
                color: "#C46A69",
                iconSmall: "fa fa-times fa-2x fadeInRight animated",
                timeout: 4000
            });
        }
        else {
            if (postdata.amt.includes("$")) { postdata.amt = postdata.amt.substring(1); }
            if (postdata.fees.includes("$")) { postdata.fees = postdata.fees.substring(1); }

            if (this.state.editMode === 'edit') {
                Axios.post(`/api/UpdateMemberDues`, postdata
                ).then(response => {
                    this.setState({
                        editMode: 'add',
                        showDuesModal: false,
                        formFields: {}
                    });
                    this.isDirty = false;
                    
                    smallBox({
                        title: "Notice",
                        content: "<i class='fa fa-clock-o'></i> <i>Member Dues record updated successfully</i>",
                        color: "#659265",
                        iconSmall: "fa fa-check fa-2x fadeInRight animated",
                        timeout: 4000
                    });
                }).catch(error => {
                    console.log(error);
                });
            }
            else {
                Axios.post(`/api/AddNewMemberDues`, postdata
                ).then(response => {
                    this.setState({
                        editMode: 'add',
                        showDuesModal: false,
                        formFields: {}
                    });
                    this.isDirty = false;

                    smallBox({
                        title: "Notice",
                        content: "<i class='fa fa-clock-o'></i> <i>Member Dues record added successfully</i>",
                        color: "#659265",
                        iconSmall: "fa fa-check fa-2x fadeInRight animated",
                        timeout: 4000
                    });
                }).catch(error => {
                    console.log(error);
                });
            }
            this.refreshData();
        }
    }

    render() {
        function dateFormatter(cell, row) {
            if (!cell) {
                return "";
            }
            return `${Moment(cell).format("MM-DD-YYYY") ? Moment(cell).format("MM-DD-YYYY") : Moment(cell).format("MM-DD-YYYY")}`;
        }

        //let dollarUSLocale = Intl.NumberFormat('en-US');

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        })

        function priceFormatter(cell, row) {
            return formatter.format(cell);
        }

        const { ToggleList } = ColumnToggle;
        const columns = [{
            dataField: 'amt',
            text: 'Amount',
            formatter: priceFormatter
        }, {
            dataField: 'fees',
            text: 'Fees',
            formatter: priceFormatter
        }, {
            dataField: 'pD_DATE',
            text: 'Date Paid',
            formatter: dateFormatter
        }, {
            dataField: 'pD_THRU',
            text: 'Paid Thru',
            formatter: dateFormatter
        }, {
            dataField: 'cK_CASH',
            text: 'Check or Cash'
        }, {
            dataField: 'cK_NO',
            text: 'Check #'
        }];

        const columnsMobile = [{
            dataField: 'amt',
            text: 'Amount'
        }, {
            dataField: 'fees',
            text: 'Fees'
        }];

        const pagination = {
            sizePerPage: 10,
            pageStartIndex: 1,
            alwaysShowAllBtns: true,
            sizePerPageList: [{ text: "5", value: 5 }, { text: "10", value: 10 }, { text: "50", value: 50 }, { text: "100", value: 100 }], // A numeric array is also available. the purpose of above example is custom the text
            showTotal: true
        };

        const paginationMobile = {
            sizePerPage: 10,
            pageStartIndex: 1,
            alwaysShowAllBtns: true,
            showTotal: false
        };

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            hideSelectColumn: true,
            style: { background: 'pink' },
            selected: [this.state.selectedMember && this.state.selectedMember.memberModel.memdueS_ID]
        };


        const rowEvents = {
            onClick: (e, row) => {
                this.selectDuesRow(row);
            }
        };

        return (
            <SlidingPane
                className='some-custom-class'
                overlayClassName='showCard'
                isOpen={this.state.showPane}
                title={this.state.slidePanelTitle}
                onRequestClose={this.hidePane}
            >
                <div className="slide-pane-body scrollableDiv">
                    <div>
                        {!this.state.loading &&
                            <Fragment>
                                <h2>Member: {this.state.memberName}<button className="btn btn-submit float-right" onClick={this.handleAddDuesPayment}>Add Dues Payment</button></h2>
                                {this.state.memberDues.length == 0 ?
                                    <h2><div className="color-maroon">No Dues History Found</div></h2>
                                    :
                                    <Card className="local777Card">
                                        <Card.Header><span>Member Dues History</span></Card.Header>
                                        <Card.Body>
                                            <form>
                                                <div className="row">
                                                    <div className="frame-wrap">
                                                        {this.state.loading ?
                                                            <span className="frame-heading"><ReactLoading className='tableLoading' type={"spokes"} color={'#F30079'} height={30} width={30} /> Loading ...</span>
                                                            :
                                                            <div>
                                                            
                                                                    <Fragment>

                                                                        <div className="fullTable">
                                                                            <ToolkitProvider
                                                                                keyField='memdueS_ID'
                                                                                data={this.state.memberDues}
                                                                                columns={columns}
                                                                                columnToggle
                                                                            >
                                                                                {
                                                                                    props => (
                                                                                        <div>
                                                                                            <BootstrapTable
                                                                                                pagination={paginationFactory(pagination)}
                                                                                                {...props.baseProps}
                                                                                                rowEvents={rowEvents}
                                                                                                selectRow={selectRow}
                                                                                                hover condensed />
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </ToolkitProvider>
                                                                        </div>
                                                                        <div className="mobileTable">
                                                                            <BootstrapTable keyField='memdueS_ID'
                                                                                data={this.state.memberDues}
                                                                                columns={columnsMobile}
                                                                                pagination={paginationFactory(paginationMobile)}
                                                                                rowEvents={rowEvents}
                                                                                hover condensed />
                                                                        </div>
                                                                    </Fragment>

                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </form>
                                        </Card.Body>
                                    </Card>
                                }
                            </Fragment>
                        }
                    </div>
                </div>
                <div className="row margin-25-10">
                    <div className="col-12">
                        <Modal size='lg' show={this.state.showDuesModal} backdrop='static' centered='true'>
                            <Modal.Header>
                                {this.state.editMode === 'edit' ?
                                    <h1>Edit Existing Dues Payment</h1>
                                    :
                                    <h1>Add New Dues Payment</h1>
                                }
                            </Modal.Header>
                            <Modal.Body>
                                <form>
                                    <div className="row">
                                        <div className="form-group col-lg-3">
                                            <label className="form-label">Dues Amount:</label>
                                            <NumberFormat thousandSeparator={true} prefix={'$'} name="amt" value={this.state.formFields.amt || ''} onChange={this.handleInputChange} className={!this.state.formFields.amt && this.state.btnModalSubmitClicked ? 'form-control-custom required' : 'form-control-custom'} />
                                        </div>
                                        <div className="form-group col-lg-3">
                                            <label className="form-label">Fees:</label>
                                            <NumberFormat thousandSeparator={true} prefix={'$'} name="fees" value={this.state.formFields.fees || ''} onChange={this.handleInputChange} className={!this.state.formFields.fees && this.state.btnModalSubmitClicked ? 'form-control-custom required' : 'form-control-custom'} />
                                        </div>
                                        <div className="form-group col-lg-2">
                                            <label className="form-label">Date Paid</label>
                                            <DatePicker
                                                selected={this.state.formFields.pD_DATE !== null && Moment(this.state.formFields.pD_DATE).toDate()}
                                                onChange={(date) => this.handleDateChange('pD_DATE', date)}
                                                className={!this.state.formFields.pD_DATE && this.state.btnModalSubmitClicked ? 'form-control-custom required' : 'form-control-custom'}
                                            />
                                        </div>
                                        <div className="form-group col-lg-2">
                                            <label className="form-label">Paid Thru</label>
                                            <DatePicker
                                                selected={this.state.formFields.pD_THRU !== null && Moment(this.state.formFields.pD_THRU).toDate()}
                                                onChange={(date) => this.handleDateChange('pD_THRU', date)}
                                                className={!this.state.formFields.pD_THRU && this.state.btnModalSubmitClicked ? 'form-control-custom required' : 'form-control-custom'}
                                            />
                                        </div>
                                        <div>
                                            
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col">
                                            <label className={this.state.formFields.cK_CASH === "" && this.state.btnModalSubmitClicked ? "form-label-required" : "form-label"}>Form Of Payment:</label>
                                            <div className="form-control-custom no-border">
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" name="cK_CASH" className="custom-control-input" value={"Cash"} checked={this.state.formFields.cK_CASH === "Cash" ? true : false} onChange={this.handleInputChange} />
                                                    <span className="custom-control-label">Cash</span>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" name="cK_CASH" className="custom-control-input" value={"Check"} checked={this.state.formFields.cK_CASH === "Check" ? true : false} onChange={this.handleInputChange} />
                                                    <span className="custom-control-label">Check</span>
                                                </div>
                                            </div>
                                        
                                        </div>
                                        <div className="form-group col mr-auto">
                                            <div className="form-label">Check No</div>
                                                <input type="text" name="cK_NO" value={this.state.formFields.cK_NO || ''} onChange={this.handleInputChange} className="form-control-custom"/>
                                        
                                        </div>
                                    </div>
                                </form>
                            </Modal.Body>

                            <div className="modal-footer">
                                <div className="form-group col-12 padding-25-10">
                                    <button className="btn btn-submit" onClick={this.handleSubmit}>Update</button>
                                    <button className="btn btn-outline-default margin-left-15" onClick={() => this.setState({ showDuesModal: false })}>Cancel</button>
                                </div>
                            </div>

                        </Modal>

                    </div>

                </div>
                <div className="modal-footer">
                    <div className="form-group col-12 padding-25-10">
                        <button className="btn btn-outline-default" onClick={this.hidePane}>Return To Members List</button>
                    </div>
                </div>
            </SlidingPane>
        );
    }
}

export default withRouter(MemberDuesSlider);