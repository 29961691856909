import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { FaAngleDown } from 'react-icons/fa';
import $ from 'jquery';
import { Dropdown } from 'react-bootstrap';

import Axios from '../../config/axios';
import Globals from '../../config/globals';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import MaskedInput from 'react-text-mask'
import { smallBox } from "../../common/utils/functions";

import { Multiselect } from 'multiselect-react-dropdown';
import 'react-widgets/dist/css/react-widgets.css';

class MemberSearchForm extends Component {

    constructor(props) {
        super(props);

        console.log('Incoming MemberSearchForm Props: ' + props);
        this.state = {
            formFields: {
                lName: '',
                fName: '',
                ss_no: '',
                clock: ''
            }
        }

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);

        //this.getFacilities();
        //this.getRecallTypes();
        //this.getIssuers();

        //let defaultFacility = JSON.parse(window.sessionStorage.getItem("defaultFacility"));

        //if (defaultFacility.facilityID) {
        //    this.setState({
        //        selectedFacilities: [defaultFacility]
        //    });
        //}

    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.state.showDropdown && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.toggleDropdown();
        }
    }

    toggleDropdown = () => {
        console.log("toggle");
        if (this.state.showDropdown) {
            this.setState({
                showDropdown: false,
                formFields: {
                    lName: '',
                    fName: '',
                    ss_no: '',
                    clock: ''
                }
            });
        } else {
            this.setState({ showDropdown: true });
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        console.log(value);

        this.setState({ formFields: { ...this.state.formFields, [name]: value } }, () => console.log(this.state.formFields));
    }

    handleDateChange = (name, date) => {
        this.setState({ formFields: { ...this.state.formFields, [name]: date } }, () => {
            if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                this.isDirty = true;  // dirty
            }

        });
    }

    handleDropdownChange = (value) => {
        this.setState({
            value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        Globals.loadingMembers = true;
        this.props.updateMembersLoading();
        
        let currentRoute = this.props.history.location.pathname;

        if (currentRoute === '/members') {
            this.props.history.replace({
                pathname: '/members'
            });
        }
        else {
            this.props.history.push({
                pathname: '/members'
            });
        }

        let postdata = {
            memberModel: {
                fName: this.state.formFields.fName,
                lName: this.state.formFields.lName,
                ss_no: this.state.formFields.ss_no,
                clock: this.state.formFields.clock                
            }
        }

        //console.log(postdata);

        Globals.members = [];

        $(".search-menu").removeClass("show");
        $(".search-menu-left").removeClass("show");

        this.setState({
            formFields: {
                fName: '',
                lName: '',
                ss_no: '',
                clock: ''
            }
        });
        //console.log(postdata);

        Axios.post(`/api/GetMembersByLookup`, postdata
        ).then(response => {
            Globals.members = response.data;
            Globals.loadingMembers = false;
            Globals.searchCriteria = '';
            if (postdata.memberModel.fName != '') { Globals.searchCriteria += 'First Name = ' + postdata.memberModel.fName + ', '; }
            if (postdata.memberModel.lName != '') { Globals.searchCriteria += 'Last Name = ' + postdata.memberModel.lName + ', '; }
            if (postdata.memberModel.ss_no != '') { Globals.searchCriteria += 'SS # = ' + postdata.memberModel.ss_no + ', '; }
            if (postdata.memberModel.clock != '') { Globals.searchCriteria += 'Clock # = ' + postdata.memberModel.clock + ', '; }
            if (Globals.searchCriteria != '') { Globals.searchCriteria = Globals.searchCriteria.substring(0, Globals.searchCriteria.length - 2); }

            console.log('Globals.searchCriteria: ' + Globals.searchCriteria);
            console.log(Globals.searchCriteria);

            if (Globals.members.length == 0) {
                smallBox({
                    title: "Notice",
                    content: "<i class='fa fa-clock-o'></i> <i>No members found matching your search criteria. Displaying all members.</i>",
                    color: "#C46A69",
                    iconSmall: "fa fa-times fa-2x fadeInRight animated",
                    timeout: 4000
                });

                let postdata = {

                };

                Axios.post(`/api/GetAllMembers`, postdata
                ).then(response => {
                    //const t1 = performance.now(); console.log(`Call to doSomething took ${t1 - t0} milliseconds.`);

                    Globals.members = response.data;
                    Globals.loadingMembers = false;
                    this.props.updateMembers();
                }).catch(error => {
                    console.log(error);
                });
            }
            else {
                this.props.updateMembers();
            }
        }).catch(error => {
            console.log(error);
        });
    }

    render() {
        const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
            <span href=""
                ref={ref}
                onClick={e => {
                    e.preventDefault();
                    onClick(e);
                }} className="inline-button" id="dropdownMenuOffset" data-offset="0,10">

                <FaAngleDown />

            </span>
        ));

        return (
            <div>
                    <Dropdown className="search-div" show={this.state.showDropdown} ref={this.setWrapperRef}>


                        <form onSubmit={this.handleSubmit} >
                            <input type="text" id="" name="lName" placeholder="Search For A Member" value={this.state.formFields.lName} onChange={this.handleInputChange} className="form-control-custom" />
                        </form>
                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" onClick={() => this.toggleDropdown()}
                            onBlur={() => this.toggleDropdown()}>
                            Custom toggle
                        </Dropdown.Toggle>
                        <span onClick={this.handleSubmit} title="Search" className="search-inline-button">
                            <i className="fa fa-search" />
                        </span>

                    <Dropdown.Menu className={"dropdown-menu " + (this.props.from === 'nav' ? 'search-menu' : 'search-menu-left')} aria-labelledby="dropdownMenuOffset">
                            <div className="panel-container show">
                                <div className="panel-content">
                                    <form onSubmit={this.handleSubmit}>

                                        <div className="row">
                                            <div className="col col-md-6 form-group">
                                                <label className="form-label">Last Name</label>
                                                <input type="text" id="" name="lName" value={this.state.formFields.lName} onChange={this.handleInputChange} className="form-control-custom" />
                                            </div>

                                            <div className="col col-md-6 form-group">
                                                <label className="form-label">First Name</label>
                                                <input type="text" name="fName" value={this.state.formFields.fName} onChange={this.handleInputChange} className="form-control-custom" />
                                            </div>

                                            <div className="col col-md-6 form-group">
                                                <label className="form-label">SSN #</label>
                                                <input type="text" name="ss_no" value={this.state.formFields.ss_no} onChange={this.handleInputChange} className="form-control-custom" />
                                            </div>

                                            <div className="col col-md-6 form-group">
                                                <label className="form-label">Clock #</label>
                                                <input type="text" name="clock" value={this.state.formFields.clock} onChange={this.handleInputChange} className="form-control-custom" />
                                            </div>

                                        </div>

                                        <div className="modal-footer">
                                            <div className="form-group col-12 padding-25-10">
                                            <button className="btn btn-default" type="submit" onClick={this.handleSubmit}>Search</button>
                                                <button className="btn btn-outline-default margin-left-15" type="button" onClick={this.toggleDropdown}>Cancel</button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>

                        </Dropdown.Menu>
                    </Dropdown>
            </div>
        );
    }
}

export default withRouter(MemberSearchForm);