import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';

import Axios from '../../config/axios';
import Globals from '../../config/globals';
import Moment from 'moment';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from 'react-bootstrap/Card';
import '../../css/card.css';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask'
//import BootstrapTable from 'react-bootstrap-table-next';
//import paginationFactory from 'react-bootstrap-table2-paginator';
//import ToolkitProvider, { ColumnToggle } from 'react-bootstrap-table2-toolkit';
//import $ from 'jquery';

//import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
//import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
//import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
//import ReactLoading from 'react-loading';
//import { Fragment } from 'react';
//import { OverlayTrigger, Popover } from 'react-bootstrap';

import { smallBox, SmartMessageBox } from "../../common/utils/functions";
import $ from 'jquery';

class MemberInfo extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        //console.log(nextProps.location.state.selectedObject.memberModel);
        //console.log(prevState.formFields.addresS2);

        //if (nextProps.location.state.selectedObject.memberModel.addresS2 !== prevState.formFields.addresS2) {
        //    return {
        //        formFields: nextProps.formFields
        //    };
        //}
        return null;
    }

    constructor(props) {
        super(props);

        console.log(this.props);
        
        this.state = {
            member: {},
            formFields: {},
            loading: true,
        }

        this.isDirty = false;
        this.checkOnDirty = true;
        this.popEventListnerAdded = false;
        this.allowModal = true;

        this.setFormFields = this.setFormFields.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getFormattedDate = this.getFormattedDate.bind(this);
    }

    componentDidMount() {
        this.getMemberInfo();

        let props = this;
        if (!this.popEventListnerAdded) {
            $(window).on("popstate", function (event) {
                props.popEventListnerAdded = true;
                event.preventDefault();
                event.stopPropagation();

                if (props.isDirty && props.allowModal) {
                    console.log("came back");
                    SmartMessageBox(
                        {
                            title: "You are about to lose all changes.",
                            content:
                                "Are you sure you would like to proceed?",
                            buttons: "[No][Yes]"
                        },
                        (ButtonPressed) => {
                            if (ButtonPressed === "Yes") {
                                window.history.back();
                                props.allowModal = false;
                                props.isDirty = false;
                                Globals.isDirtyPage = false;
                                return 0;
                            }
                            if (ButtonPressed === "No") {
                                window.history.pushState(null, null, null);
                                return 0;
                            }
                        }
                    );
                }
            });
        }
    }

    //componentDidMount() {
    //    this.getMemberInfo();
    //}

    componentWillUnmount() {
        //this._isMounted = false;
        //$(document.body).off('keydown', this.handleKeyDown);
    }

    componentDidUpdate() {
        if (this.checkOnDirty && this.isDirty) {
            this.checkOnDirty = false;
            window.history.pushState(null, null, null);
        }
    }

    getMemberInfo = () => {
        let postdata = {
            ss_no: this.props.location.state.ss_no
        };

        Axios.post(`/api/GetMemberInformationBySSN`, postdata
        ).then(response => {
            this.setState({
                formFields: response.data.memberModel,
                member: response.data.memberModel,
                loading: false
            }, () => console.log(this.state));
        }).catch(error => {
            console.log(error);
        });
    }

    setFormFields() {

        let formFields = this.state.formFields;

        this.setState({
            formFields
        });
    }

    showToast(message) {
        if (message === 'Success') {
            this.notifySuccess();
        }
        else {
            this.notifyFail();
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        //this.setState({ formFields: { ...this.state.formFields, [name]: value } });

        this.setState({ formFields: { ...this.state.formFields, [name]: value } }, () => {
            if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                this.isDirty = true;  // dirty
            }
        });
    }

    handleDateChange = (name, date) => {
        this.setState({ formFields: { ...this.state.formFields, [name]: date } });
    }

    handleSubmit() {
        let membersModel = this.state.formFields;

        Axios.post(`/api/UpdateMember`, membersModel
        ).then(response => {
            smallBox({
                title: "Notice",
                content: "<i class='fa fa-clock-o'></i> <i>Member Information Successfully Updated</i>",
                color: "#659265",
                iconSmall: "fa fa-check fa-2x fadeInRight animated",
                timeout: 4000
            });

        }).catch(error => {
            smallBox({
                title: "Notice",
                content: "<i class='fa fa-clock-o'></i> <i>Problem Encountered Updating Member</i>",
                color: "#C46A69",
                iconSmall: "fa fa-times fa-2x fadeInRight animated",
                timeout: 4000
            });

            console.log(error);
        });
    }

    getFormattedDate(str) {
        var momentDate = Moment(str);
        console.log(str);

        return str && momentDate.isValid() ? momentDate.format('MM/DD/YYYY') : '';
    }

    render() {
        return (
            <div>
                {!this.state.loading &&
                    <Fragment>
                <Card className="local777Card">
                        <Card.Header><span>General Information</span> <Link className="btn btn-submit float-right" to="/members">Return To Members List</Link>
                    </Card.Header>
                    <Card.Body>
                        <form>
                            <div className="row">
                                <div className="form-group col-lg-3">
                                    <label className="form-label">Last Name</label>
                                    <input type="text" name="lname" value={this.state.formFields.lname || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-3">
                                    <label className="form-label">First Name</label>
                                    <input type="text" name="fname" value={this.state.formFields.fname || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-3">
                                    <label className="form-label">Middle Initial</label>
                                    <input type="text" name="mi" value={this.state.formFields.mi || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-3">
                                    <label className="form-label">Card No.</label>
                                    <input type="text" name="carD_NO" value={this.state.formFields.carD_NO || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>

                                <div className="form-group col-lg-2">
                                    <label className="form-label">Address</label>
                                    <input type="text" name="address" value={this.state.formFields.address || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-2">
                                    <label className="form-label">Address 2</label>
                                    <input type="text" name="addresS2" value={this.state.formFields.addresS2 || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-2">
                                    <label className="form-label">Street</label>
                                    <input type="text" name="street" value={this.state.formFields.street || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-2">
                                    <label className="form-label">City</label>
                                    <input type="text" name="city" value={this.state.formFields.city || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-2">
                                    <label className="form-label">State</label>
                                    <input type="text" name="state" value={this.state.formFields.state || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-2">
                                    <label className="form-label">Zip</label>
                                    <input type="text" name="zip" value={this.state.formFields.zip || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>

                                <div className="form-group col-lg-3">
                                    <label className="form-label">Phone 1</label>
                                    <input type="text" name="phonE1" value={this.state.formFields.phonE1 || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-3">
                                    <label className="form-label">Phone 2</label>
                                    <input type="text" name="phonE2" value={this.state.formFields.phonE2 || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-3">
                                    <label className="form-label">Cell</label>
                                    <input type="text" name="cell" value={this.state.formFields.cell || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-3">
                                    <label className="form-label">Beeper</label>
                                    <input type="text" name="beeper" value={this.state.formFields.beeper || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>

                                <div className="form-group col-lg-2">
                                    <label className="form-label">SSN</label>
                                    <input type="text" name="ssn" value={this.state.formFields.sS_NO || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-2">
                                    <label className="form-label">Clock #</label>
                                    <input type="text" name="clock" value={this.state.formFields.clock || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-2">
                                    <label className="form-label">Status</label>
                                    <input type="text" name="status" value={this.state.formFields.status || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-2">
                                    <label className="form-label">Birth Date</label>
                                    <DatePicker
                                        onChange={(date) => this.handleDateChange('birtH_DATE', date)}
                                        className="form-control-custom"
                                        selected={this.state.formFields.birtH_DATE && Moment(this.state.formFields.birtH_DATE).toDate()}
                                        customInput={
                                            <MaskedInput
                                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                keepCharPositions={true}
                                                guide={true}
                                            />

                                        }
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </div>
                                <div className="form-group col-lg-2">
                                    <label className="form-label">Sex</label>
                                    <input type="text" name="sex" value={this.state.formFields.sex || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-2">
                                    <label className="form-label">Race</label>
                                    <input type="text" name="race" value={this.state.formFields.race || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>

                                <div className="form-group col-lg-1">
                                    <label className="form-label">Department</label>
                                    <input type="text" name="dept" value={this.state.formFields.dept || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-1">
                                    <label className="form-label">Job Class</label>
                                    <input type="text" name="joB_CLASS" value={this.state.formFields.joB_CLASS || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-1">
                                    <label className="form-label">Pay Rate</label>
                                    <input type="text" name="paY_RATE" value={this.state.formFields.paY_RATE || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-1">
                                    <label className="form-label">Shop</label>
                                    <input type="text" name="shop" value={this.state.formFields.shop || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-1">
                                    <label className="form-label">Shift</label>
                                    <input type="text" name="shift" value={this.state.formFields.shift || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-1">
                                    <label className="form-label">Trade</label>
                                    <input type="text" name="trade" value={this.state.formFields.trade || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-1">
                                    <label className="form-label">Dues Class</label>
                                    <input type="text" name="dueS_CLASS" value={this.state.formFields.dueS_CLASS || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-1">
                                    <label className="form-label">Dues Rate</label>
                                    <input type="text" name="dueS_RATE" value={this.state.formFields.dueS_RATE || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                </div>
                                <div className="form-group col-lg-4">
                                    
                                </div>         
                            </div>
                        </form>
                    </Card.Body>
                </Card>

                <Card className="local777Card">
                    <Card.Header><span>Service Dates</span>
                    </Card.Header>
                    <Card.Body>
                        <form>
                            <div className="row">
                                <div className="form-group col-lg-2">
                                    <label className="form-label">Initiation Date</label>
                                    <DatePicker
                                        onChange={(date) => this.handleDateChange('iniT_DATE', date)}
                                        className="form-control-custom"
                                        selected={this.state.formFields.iniT_DATE && Moment(this.state.formFields.iniT_DATE).toDate()}
                                        customInput={
                                            <MaskedInput
                                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                keepCharPositions={true}
                                                guide={true}
                                            />

                                        }
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </div>
                                <div className="form-group col-lg-2">
                                    <label className="form-label">Orig Hire Date</label>
                                    <DatePicker
                                        onChange={(date) => this.handleDateChange('oriG_HIRE_DT', date)}
                                        className="form-control-custom"
                                        selected={this.state.formFields.oriG_HIRE_DT && Moment(this.state.formFields.oriG_HIRE_DT).toDate()}
                                        customInput={
                                            <MaskedInput
                                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                keepCharPositions={true}
                                                guide={true}
                                            />

                                        }
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </div>
                                <div className="form-group col-lg-2">
                                    <label className="form-label">Withdrawal Date</label>
                                    <DatePicker
                                        onChange={(date) => this.handleDateChange('wD_DT', date)}
                                        className="form-control-custom"
                                        selected={this.state.formFields.wD_DT && Moment(this.state.formFields.wD_DT).toDate()}
                                        customInput={
                                            <MaskedInput
                                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                keepCharPositions={true}
                                                guide={true}
                                            />

                                        }
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </div>
                                <div className="form-group col-lg-2">
                                    <label className="form-label">Reinitiation Date</label>
                                    <DatePicker
                                        onChange={(date) => this.handleDateChange('reiniT_DT', date)}
                                        className="form-control-custom"
                                        selected={this.state.formFields.reiniT_DT && Moment(this.state.formFields.reiniT_DT).toDate()}
                                        customInput={
                                            <MaskedInput
                                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                keepCharPositions={true}
                                                guide={true}
                                            />

                                        }
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </div>
                                <div className="form-group col-lg-2">
                                    <label className="form-label">Transfer Date</label>
                                    <DatePicker
                                        onChange={(date) => this.handleDateChange('tranfeR_DT', date)}
                                        className="form-control-custom"
                                        selected={this.state.formFields.tranfeR_DT && Moment(this.state.formFields.tranfeR_DT).toDate()}
                                        customInput={
                                            <MaskedInput
                                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                keepCharPositions={true}
                                                guide={true}
                                            />

                                        }
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </div>
                                <div className="form-group col-lg-2">
                                    <label className="form-label">Expulsion Date</label>
                                    <DatePicker
                                        onChange={(date) => this.handleDateChange('expuL_DT', date)}
                                        className="form-control-custom"
                                        selected={this.state.formFields.expuL_DT && Moment(this.state.formFields.expuL_DT).toDate()}
                                        customInput={
                                            <MaskedInput
                                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                keepCharPositions={true}
                                                guide={true}
                                            />

                                        }
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </div>

                                <div className="form-group col-lg-2">
                                    <label className="form-label">Retire Date</label>
                                    <DatePicker
                                        onChange={(date) => this.handleDateChange('retirE_DT', date)}
                                        className="form-control-custom"
                                        selected={this.state.formFields.retirE_DT && Moment(this.state.formFields.retirE_DT).toDate()}
                                        customInput={
                                            <MaskedInput
                                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                keepCharPositions={true}
                                                guide={true}
                                            />

                                        }
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </div>
                                <div className="form-group col-lg-2">
                                    <label className="form-label">Deceased Date</label>
                                    <DatePicker
                                        onChange={(date) => this.handleDateChange('deC_DT', date)}
                                        className="form-control-custom"
                                        selected={this.state.formFields.deC_DT && Moment(this.state.formFields.deC_DT).toDate()}
                                        customInput={
                                            <MaskedInput
                                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                keepCharPositions={true}
                                                guide={true}
                                            />

                                        }
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </div>
                                <div className="form-group col-lg-2">
                                    <label className="form-label">Rehire Date</label>
                                    <DatePicker
                                        onChange={(date) => this.handleDateChange('rehirE_DT', date)}
                                        className="form-control-custom"
                                        selected={this.state.formFields.rehirE_DT && Moment(this.state.formFields.rehirE_DT).toDate()}
                                        customInput={
                                            <MaskedInput
                                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                keepCharPositions={true}
                                                guide={true}
                                            />

                                        }
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </div>
                                <div className="form-group col-lg-2">
                                    <label className="form-label">Service Date</label>
                                    <DatePicker
                                        onChange={(date) => this.handleDateChange('servicE_DT', date)}
                                        className="form-control-custom"
                                        selected={this.state.formFields.servicE_DT && Moment(this.state.formFields.servicE_DT).toDate()}
                                        customInput={
                                            <MaskedInput
                                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                keepCharPositions={true}
                                                guide={true}
                                            />

                                        }
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </div>
                                <div className="form-group col-lg-2">
                                    <label className="form-label">Seniority Date</label>
                                    <DatePicker
                                        onChange={(date) => this.handleDateChange('senioritY_DT', date)}
                                        className="form-control-custom"
                                        selected={this.state.formFields.senioritY_DT && Moment(this.state.formFields.senioritY_DT).toDate()}
                                        customInput={
                                            <MaskedInput
                                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                keepCharPositions={true}
                                                guide={true}
                                            />

                                        }
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </div>
                                <div className="form-group col-lg-2">
                                    <label className="form-label">Layoff Date</label>
                                    <DatePicker
                                        onChange={(date) => this.handleDateChange('layofF_DT', date)}
                                        className="form-control-custom"
                                        selected={this.state.formFields.layofF_DT && Moment(this.state.formFields.layofF_DT).toDate()}
                                        customInput={
                                            <MaskedInput
                                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                keepCharPositions={true}
                                                guide={true}
                                            />

                                        }
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </div>

                                <div className="form-group col-lg-2">
                                    <label className="form-label">Termination Date</label>
                                    <DatePicker
                                        onChange={(date) => this.handleDateChange('terM_DT', date)}
                                        className="form-control-custom"
                                        selected={this.state.formFields.terM_DT && Moment(this.state.formFields.terM_DT).toDate()}
                                        customInput={
                                            <MaskedInput
                                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                keepCharPositions={true}
                                                guide={true}
                                            />

                                        }
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </div>
                                <div className="form-group col-lg-10">

                                </div>
                            </div>
                        </form>
                    </Card.Body>
                </Card>

                <Card className="local777Card">
                    <Card.Header><span>Notes</span>
                    </Card.Header>
                    <Card.Body>
                        <form>
                            <div className="form-group col-lg-12">
                                <textarea name="notes" value={this.state.formFields.notes || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>
                        </form>
                    </Card.Body>
                </Card>

                    <button className="btn btn-submit" onClick={this.handleSubmit}>Save Changes</button>   <Link className="btn btn-outline-default" to="/members">Return To Members List</Link>
                </Fragment>
                }
            </div>
        );
    }
}

export default withRouter(MemberInfo);