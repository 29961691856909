import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.css';
import './css/vendors.bundle.css';
import './css/app.bundle.css';
import './css/custom.css';
import './css/spacing.css';
import './css/entityStyles.css';
import './css/table.css';

import './css/reactions.css';
import './css/jqvmap.bundle.css';
import './css/message-box.css';

import './css/fa-brands.css';
import './css/fa-regular.css';
import './css/fa-solid.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { AppContainer } from 'react-hot-loader';


//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const render = () => {
  ReactDOM.render(
  <BrowserRouter>
    <AppContainer>
      <App />
    </AppContainer>
  </BrowserRouter>, document.getElementById('root')
  );
};

//ReactDOM.render(
//  <BrowserRouter>
//    <App />
//  </BrowserRouter>, document.getElementById('root')
//);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Render once
render();

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./App', () => {
    render();
  });
}


