import React, { Component, Fragment } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { withRouter } from "react-router-dom";

import Axios from '../../config/axios';
import Globals from '../../config/globals';
import Moment from 'moment';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from 'react-bootstrap/Card';
import '../../css/card.css';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask'

import { smallBox, SmartMessageBox } from "../../common/utils/functions";
import $ from 'jquery';

class MemberInfoSlider extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        
        if (nextProps.showPane === true && nextProps.showPane !== prevState.showPane) {
            console.log(nextProps);
            return nextProps.showPane === true ? { showPane: true, memberSSN: nextProps.memberSSN, loading: true, members: nextProps.members, selectedIdx: nextProps.selectedIdx, slidePanelTitle: nextProps.slidePanelTitle, btnSubmitClicked: false } : { showPane: false };
        }
        
        return null;
    }

    constructor(props) {
        super(props);

        this.state = {
            showPane: false,
            memberSSN: '',
            member: {},
            formFields: {},
            loading: true,
            slidePanelTitle: '',
            btnSubmitClicked: false
        }

        this.isDirty = false;
        this.checkOnDirty = true;
        this.popEventListnerAdded = false;
        this.allowModal = true;

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getFormattedDate = this.getFormattedDate.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.showPane !== this.state.showPane && this.state.showPane === true) {
            if (this.state.memberSSN !== null) {
                let postdata = {
                    ss_no: this.state.memberSSN
                };

                console.log('Edit Member');

                Axios.post(`/api/GetMemberInformationBySSN`, postdata
                ).then(response => {
                    this.setState({
                        formFields: response.data.memberModel,
                        origFormFields: response.data.memberModel,
                        member: response.data.memberModel,
                        loading: false,
                        slidePanelTitle: 'Update Member Information'
                    }, () => console.log('hello'));
                }).catch(error => {
                    console.log('here in err');
                });
            }
            else {
                this.setState({
                    member: {},
                    formFields: {},
                    origFormFields: {},
                    loading: false,
                    slidePanelTitle: 'Add New Member'
                }, () => console.log('hello'));
            }
        };
    }

    componentDidMount() {

    }

    hidePane = () => {
        this.setState({
            showPane: false
        });

        this.props.hidePane();
    }

    showToast(message) {
        if (message === 'Success') {
            this.notifySuccess();
        }
        else {
            this.notifyFail();
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        console.log(this.isDirty);
        //console.log(name);
        //console.log(value);

        this.setState({ formFields: { ...this.state.formFields, [name]: value } }, () => {
            //console.log(this.state.formFields);
            //console.log(this.state.origFormFields);

            if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.state.origFormFields)) {
                console.log('isDirty');
                this.isDirty = true;  // dirty
            }
        });

        console.log(this.isDirty);
    }

    handleDateChange = (name, date) => {
        this.setState({ formFields: { ...this.state.formFields, [name]: date } });
    }

    handleSubmit() {
        //console.log(this.state.formFields);

        this.setState({
            btnSubmitClicked: true
        });

        if (!this.state.formFields?.lname ||
            !this.state.formFields?.fname ||
            !this.state.formFields?.carD_NO ||
            !this.state.formFields?.sS_NO
        ) {
            smallBox({
                title: "Notice",
                content: "<i class='fa fa-clock-o'></i> <i>Please fill out all required fields.</i>",
                color: "#C46A69",
                iconSmall: "fa fa-times fa-2x fadeInRight animated",
                timeout: 4000
            });
        } else {
            if (this.state.memberSSN !== null) {
                Axios.post(`/api/UpdateMember`, this.state.formFields
                ).then(response => {
                    smallBox({
                        title: "Notice",
                        content: "<i class='fa fa-clock-o'></i> <i>Member Information Successfully Updated</i>",
                        color: "#659265",
                        iconSmall: "fa fa-check fa-2x fadeInRight animated",
                        timeout: 4000
                    });

                    //now update the parent list
                    //the next line basically creates kvp with an object named memberModel 
                    let membersModel = { memberModel: this.state.formFields };
                    let members = this.state.members;
                    members[this.state.selectedIdx] = membersModel;
                    this.props.updateMembers(members);

                }).catch(error => {
                    smallBox({
                        title: "Notice",
                        content: "<i class='fa fa-clock-o'></i> <i>Problem Encountered Updating Member</i>",
                        color: "#C46A69",
                        iconSmall: "fa fa-times fa-2x fadeInRight animated",
                        timeout: 4000
                    });

                    console.log(error);
                });
            }
            else {
                Axios.post(`/api/AddNewMember`, this.state.formFields
                ).then(response => {
                    smallBox({
                        title: "Notice",
                        content: "<i class='fa fa-clock-o'></i> <i>New Member Successfully Added</i>",
                        color: "#659265",
                        iconSmall: "fa fa-check fa-2x fadeInRight animated",
                        timeout: 4000
                    });

                    //now update the parent list
                    //the next line basically creates kvp with an object named memberModel 
                    let membersModel = { memberModel: this.state.formFields };
                    let members = this.state.members;
                    members[this.state.selectedIdx] = membersModel;
                    this.props.updateMembers(members);

                }).catch(error => {
                    smallBox({
                        title: "Notice",
                        content: "<i class='fa fa-clock-o'></i> <i>Problem Encountered Adding New Member</i>",
                        color: "#C46A69",
                        iconSmall: "fa fa-times fa-2x fadeInRight animated",
                        timeout: 4000
                    });

                    console.log(error);
                });
            }
        }

        //this.hidePane();
    }

    getFormattedDate(str) {
        var momentDate = Moment(str);
        console.log(str);

        return str && momentDate.isValid() ? momentDate.format('MM/DD/YYYY') : '';
    }

    render() {

        return (
            <SlidingPane
                className='some-custom-class'
                overlayClassName='showCard'
                isOpen={this.state.showPane}
                title={this.state.slidePanelTitle}
                onRequestClose={this.hidePane}
            >
                <div className="slide-pane-body scrollableDiv">
                    <div>
                        {!this.state.loading &&
                            <Fragment>
                                {this.state.memberSSN && (<h2>Member: {this.state.member.fname + ' ' + this.state.member.lname}</h2>)}
                                {!this.state.memberSSN && (<h2>New Member</h2>)}

                                <Card className="local777Card">
                                    <Card.Header><span>General Information</span></Card.Header>
                                    <Card.Body>
                                    <label className="form-label">* Indicates Required Field</label>
                                        <form>
                                            <div className="row">
                                                <div className="form-group col-lg-3">
                                                    <label className="form-label">* Last Name</label>
                                                <input type="text" name="lname" value={this.state.formFields.lname || ''} onChange={this.handleInputChange} className={!this.state.formFields.lname && this.state.btnSubmitClicked ? 'form-control-custom required' : 'form-control-custom'} />
                                            </div>
                                                <div className="form-group col-lg-3">
                                                    <label className="form-label">* First Name</label>
                                                <input type="text" name="fname" value={this.state.formFields.fname || ''} onChange={this.handleInputChange} className={!this.state.formFields.fname && this.state.btnSubmitClicked ? 'form-control-custom required' : 'form-control-custom'} />
                                                </div>
                                                <div className="form-group col-lg-3">
                                                    <label className="form-label">Middle Initial</label>
                                                    <input type="text" name="mi" value={this.state.formFields.mi || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                                </div>
                                                <div className="form-group col-lg-3">
                                                    <label className="form-label">* Card No.</label>
                                                <input type="text" name="carD_NO" value={this.state.formFields.carD_NO || ''} onChange={this.handleInputChange} className={!this.state.formFields.carD_NO && this.state.btnSubmitClicked ? 'form-control-custom required' : 'form-control-custom'} />
                                                </div>

                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Address</label>
                                                    <input type="text" name="address" value={this.state.formFields.address || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Address 2</label>
                                                    <input type="text" name="addresS2" value={this.state.formFields.addresS2 || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Street</label>
                                                    <input type="text" name="street" value={this.state.formFields.street || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">City</label>
                                                    <input type="text" name="city" value={this.state.formFields.city || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">State</label>
                                                    <input type="text" name="state" value={this.state.formFields.state || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Zip</label>
                                                    <input type="text" name="zip" value={this.state.formFields.zip || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                                </div>

                                                <div className="form-group col-lg-3">
                                                    <label className="form-label">Phone 1</label>
                                                    <input type="text" name="phonE1" value={this.state.formFields.phonE1 || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                                </div>
                                                <div className="form-group col-lg-3">
                                                    <label className="form-label">Phone 2</label>
                                                    <input type="text" name="phonE2" value={this.state.formFields.phonE2 || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                                </div>
                                                <div className="form-group col-lg-3">
                                                    <label className="form-label">Cell</label>
                                                    <input type="text" name="cell" value={this.state.formFields.cell || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                                </div>
                                                <div className="form-group col-lg-3">
                                                    <label className="form-label">Beeper</label>
                                                    <input type="text" name="beeper" value={this.state.formFields.beeper || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                                </div>

                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">* SSN</label>
                                                <input type="text" name="sS_NO" value={this.state.formFields.sS_NO || ''} onChange={this.handleInputChange} className={!this.state.formFields.sS_NO && this.state.btnSubmitClicked ? 'form-control-custom required' : 'form-control-custom'} />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Clock #</label>
                                                    <input type="text" name="clock" value={this.state.formFields.clock || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Status</label>
                                                    <input type="text" name="status" value={this.state.formFields.status || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                <label className="form-label">Birth Date</label>
                                                <DatePicker
                                                    selected={this.state.formFields.birtH_DATE ? Moment(this.state.formFields.birtH_DATE).toDate() : ''}
                                                    onChange={(date) => this.handleDateChange('birtH_DATE', date)}
                                                    className="form-control-custom"
                                                    />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Sex</label>
                                                    <input type="text" name="sex" value={this.state.formFields.sex || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Race</label>
                                                    <input type="text" name="race" value={this.state.formFields.race || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                                </div>

                                                <div className="form-group col-lg-1">
                                                    <label className="form-label">Department</label>
                                                    <input type="text" name="dept" value={this.state.formFields.dept || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                                </div>
                                                <div className="form-group col-lg-1">
                                                    <label className="form-label">Job Class</label>
                                                    <input type="text" name="joB_CLASS" value={this.state.formFields.joB_CLASS || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                                </div>
                                                <div className="form-group col-lg-1">
                                                    <label className="form-label">Pay Rate</label>
                                                    <input type="text" name="paY_RATE" value={this.state.formFields.paY_RATE || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                                </div>
                                                <div className="form-group col-lg-1">
                                                    <label className="form-label">Shop</label>
                                                    <input type="text" name="shop" value={this.state.formFields.shop || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                                </div>
                                                <div className="form-group col-lg-1">
                                                    <label className="form-label">Shift</label>
                                                    <input type="text" name="shift" value={this.state.formFields.shift || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                                </div>
                                                <div className="form-group col-lg-1">
                                                    <label className="form-label">Trade</label>
                                                    <input type="text" name="trade" value={this.state.formFields.trade || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                                </div>
                                                <div className="form-group col-lg-1">
                                                    <label className="form-label">Dues Class</label>
                                                    <input type="text" name="dueS_CLASS" value={this.state.formFields.dueS_CLASS || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                                </div>
                                                <div className="form-group col-lg-1">
                                                    <label className="form-label">Dues Rate</label>
                                                    <input type="text" name="dueS_RATE" value={this.state.formFields.dueS_RATE || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                                </div>
                                                <div className="form-group col-lg-4">

                                                </div>
                                            </div>
                                        </form>
                                    </Card.Body>
                                </Card>

                                <Card className="local777Card">
                                    <Card.Header><span>Service Dates</span>
                                    </Card.Header>
                                    <Card.Body>
                                        <form>
                                            <div className="row">
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Initiation Date</label>
                                                    <DatePicker
                                                        selected={this.state.formFields.iniT_DATE ? Moment(this.state.formFields.iniT_DATE).toDate() : ''}
                                                        onChange={(date) => this.handleDateChange('iniT_DATE', date)}
                                                        className="form-control-custom"
                                                    />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Orig Hire Date</label>
                                                    <DatePicker
                                                        selected={this.state.formFields.oriG_HIRE_DT ? Moment(this.state.formFields.oriG_HIRE_DT).toDate() : ''}
                                                        onChange={(date) => this.handleDateChange('oriG_HIRE_DT', date)}
                                                        className="form-control-custom"
                                                    />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Withdrawal Date</label>
                                                    <DatePicker
                                                        selected={this.state.formFields.wD_DT ? Moment(this.state.formFields.wD_DT).toDate() : ''}
                                                        onChange={(date) => this.handleDateChange('wD_DT', date)}
                                                        className="form-control-custom"
                                                    />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Reinitiation Date</label>
                                                <DatePicker
                                                        selected={this.state.formFields.reiniT_DT ? Moment(this.state.formFields.reiniT_DT).toDate() : ''}
                                                        onChange={(date) => this.handleDateChange('reiniT_DT', date)}
                                                        className="form-control-custom"
                                                    />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Transfer Date</label>
                                                    <DatePicker
                                                        selected={this.state.formFields.tranfeR_DT ? Moment(this.state.formFields.tranfeR_DT).toDate() : ''}
                                                        onChange={(date) => this.handleDateChange('tranfeR_DT', date)}
                                                        className="form-control-custom"
                                                    />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Expulsion Date</label>
                                                    <DatePicker
                                                        selected={this.state.formFields.expuL_DT ? Moment(this.state.formFields.expuL_DT).toDate() : ''}
                                                        onChange={(date) => this.handleDateChange('expuL_DT', date)}
                                                        className="form-control-custom"
                                                    />
                                                </div>

                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Retire Date</label>
                                                    <DatePicker
                                                        selected={this.state.formFields.retirE_DT ? Moment(this.state.formFields.retirE_DT).toDate() : ''}
                                                        onChange={(date) => this.handleDateChange('retirE_DT', date)}
                                                        className="form-control-custom"
                                                    />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Deceased Date</label>
                                                    <DatePicker
                                                        selected={this.state.formFields.deC_DT ? Moment(this.state.formFields.deC_DT).toDate() : ''}
                                                        onChange={(date) => this.handleDateChange('deC_DT', date)}
                                                        className="form-control-custom"
                                                    />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Rehire Date</label>
                                                    <DatePicker
                                                        selected={this.state.formFields.rehirE_DT ? Moment(this.state.formFields.rehirE_DT).toDate() : ''}
                                                        onChange={(date) => this.handleDateChange('rehirE_DT', date)}
                                                        className="form-control-custom"
                                                    />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Service Date</label>
                                                    <DatePicker
                                                        selected={this.state.formFields.servicE_DT ? Moment(this.state.formFields.servicE_DT).toDate() : ''}
                                                        onChange={(date) => this.handleDateChange('servicE_DT', date)}
                                                        className="form-control-custom"
                                                    />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Seniority Date</label>
                                                    <DatePicker
                                                        selected={this.state.formFields.senioritY_DT ? Moment(this.state.formFields.senioritY_DT).toDate() : ''}
                                                        onChange={(date) => this.handleDateChange('senioritY_DT', date)}
                                                        className="form-control-custom"
                                                    />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Layoff Date</label>
                                                    <DatePicker
                                                        selected={this.state.formFields.layofF_DT ? Moment(this.state.formFields.layofF_DT).toDate() : ''}
                                                        onChange={(date) => this.handleDateChange('layofF_DT', date)}
                                                        className="form-control-custom"
                                                    />
                                                </div>

                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Termination Date</label>
                                                    <DatePicker
                                                        selected={this.state.formFields.terM_DT ? Moment(this.state.formFields.terM_DT).toDate() : ''}
                                                        onChange={(date) => this.handleDateChange('terM_DT', date)}
                                                        className="form-control-custom"
                                                    />
                                                </div>
                                                <div className="form-group col-lg-10">

                                                </div>
                                            </div>
                                        </form>
                                    </Card.Body>
                                </Card>

                                <Card className="local777Card">
                                    <Card.Header><span>Notes</span>
                                    </Card.Header>
                                    <Card.Body>
                                        <form>
                                            <div className="form-group col-lg-12">
                                                <textarea name="notes" value={this.state.formFields.notes || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                            </div>
                                        </form>
                                    </Card.Body>
                                </Card>

                            </Fragment>
                        }
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="form-group col-12 padding-25-10">
                        <button className="btn btn-submit" onClick={this.handleSubmit}>Save Changes</button>   <button className="btn btn-outline-default" onClick={this.hidePane}>Return To Members List</button>
                    </div>
                </div>
            </SlidingPane>
        );
    }
}

export default withRouter(MemberInfoSlider);