import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { MyToast } from '../Helpers/Toast';
import './login.css';
import logo from '../../img/UA_Local_777_logo_tiny.png';
import Globals from '../../config/globals';
import Axios from '../../config/axios';
import cookie from 'react-cookies';
import TFASetup from "./TFASetup";
import TFALogin from "./TFALogin";
import ReactLoading from 'react-loading';

import { smallBox } from "../../common/utils/functions";

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            validUserFound: true,
            mode: "noShowTwoFactor",
            formFields: {
                userid: '',
                password: ''
            },
            showToast: false,
            toastContent: {
                title: '',
                content: ''
            },
            loading: false,
        }
    }

    goLogin = () => {
        this.setState({
            mode: "noShowTwoFactor",
        });
    }

    toggleToast = (toastContent) => {
        if (toastContent) {
            this.setState({
                showToast: !this.state.showToast,
                toastContent
            });
        } else {
            this.setState({
                showToast: !this.state.showToast
            });
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        let myuserid = this.state.formFields.userid;
        let mypwd = this.state.formFields.password;

        if (myuserid === null || myuserid === "") {
            document.getElementById("userid").focus();
            return;
        }
        if (mypwd === null || mypwd === "") {
            document.getElementById("password").focus();
            return;
        }
        this.setState({
            loading: true
        });


        // ***** Validate user against db table *****
        let commandresult = "";

        let postdata = {};
        postdata.userid = this.state.formFields.userid;
        postdata.password = this.state.formFields.password;

        await Axios.post(`/api/validateuser`, postdata
        ).then(response => {
            commandresult = response.data;
        }).catch(error => {
            console.log(error);
        });

        // ***** If no limited scope bearer token returned, the user credentials were invalid *****
        if (commandresult === "") {
            this.setState({ validUserFound: false, loading: false, });

            smallBox({
                title: "Notice",
                content: "<i class='fa fa-clock-o'></i> <i>Invalid User Credentials.</i>",
                color: "#C46A69",
                iconSmall: "fa fa-times fa-2x fadeInRight animated",
                timeout: 4000
            });

            return;
        } else {
            // set limited scope bearer token where Axios knows where to find it
            let userInfo = Globals.userInfo;
            userInfo.bearer = commandresult.token;
            userInfo.fullName = commandresult.firstName + " " + commandresult.lastName;
            userInfo.isLoggedIn = true;
            Globals.userInfo = userInfo;

            let path = '/members';
            this.props.history.replace(path);

            //smallBox({
            //    title: "Notice",
            //    content: "<i class='fa fa-clock-o'></i> <i>Valid User Credentials.</i>",
            //    color: "#659265",
            //    iconSmall: "fa fa-check fa-2x fadeInRight animated",
            //    timeout: 4000
            //});

            //return;

            //console.log(commandresult);
        }

        //2FA
        if (cookie.load('bypass2FA' + myuserid)) {
            this.setState({
                validUserFound: true
            }, () => this.validateUser());
        } else {
            if (commandresult.tfaEnabled === true) {
                this.setState({
                    mode: "showTwoFactor",
                    validUserFound: true,
                    loading: false,
                });
            } else {
                this.setState({
                    mode: "showTwoFactorSetUp",
                    validUserFound: true,
                    loading: false,
                });
            }
        }
    }

    validateUser = async () => {
        let commandresult = "";

        let postdata = {};
        postdata.userid = this.state.formFields.userid;
        postdata.password = this.state.formFields.password;

        await Axios.post(`/api/token`, postdata
        ).then(response => {
            commandresult = response.data;

            let userInfo = { ...Globals.userInfo };
            userInfo.bearer = commandresult.token;
            userInfo.fullName = commandresult.FName + " " + commandresult.LName;
            userInfo.isLoggedIn = true;
            Globals.userInfo = userInfo;

            let pdata = {
                uniqueID: commandresult.userID
            }
        }).catch(error => {
            console.log(error);
        });
    }

    render() {

        return (
            <div className="background">
                <MyToast showToast={this.state.showToast} toggleToast={this.toggleToast} toastContent={this.state.toastContent} />
                <div className="blankpage-form-field">
                    <div className="page-logo m-0 w-100 align-items-center justify-content-center rounded border-bottom-left-radius-0 border-bottom-right-radius-0 px-4">
                        <img src={logo} height="30px" alt="Local777 Member Dues WebApp" aria-roledescription="logo" />
                        <span className="page-logo-text mr-1">Plumbers & Pipefitters Local 777<br />Member Dues App</span>
                    </div>
                    <div className="card p-4 border-top-left-radius-0 border-top-right-radius-0">

                        {this.state.mode === "showTwoFactor" &&
                            <TFALogin validateUser={this.validateUser} goLogin={this.goLogin} userid={this.state.formFields.userid} password={this.state.formFields.password} />
                        }
                        {this.state.mode === "showTwoFactorSetUp" &&
                            <TFASetup validateUser={this.validateUser} goLogin={this.goLogin} userid={this.state.formFields.userid} password={this.state.formFields.password} />
                        }
                        {this.state.mode === "noShowTwoFactor" &&


                        <form>
                            <div className="form-group">
                                <label className="form-label" htmlFor="userid">Username</label>
                                <input type="text" id="userid" className="form-control-custom" name="userid" value={this.state.formFields.userid} onChange={this.handleInputChange} />
                                <span className="help-block">
                                    Your username
                                </span>
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="password">Password</label>
                                <input type="password" id="password" name="password" value={this.state.formFields.password} onChange={this.handleInputChange} className="form-control-custom" />
                                <span className="help-block">
                                    Your password
                                </span>
                            </div>
                           
                            {this.state.loading &&
                                <ReactLoading className='tableLoading float-left' type={"spokes"} color={'#F30079'} height={30} width={30} />
                            }
                            <button className="btn btn-default float-right" onClick={this.handleSubmit}>Login</button>
                        </form>
                        }


                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Login);
