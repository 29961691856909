import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import { FaAngleDown } from 'react-icons/fa';
import $ from 'jquery';
import { Dropdown } from 'react-bootstrap';
import ribbonLogo from '../../img/UA_Local_777_logo.png';
import Axios from '../../config/axios';
import Globals from '../../config/globals';
import './navigation.css';
import { smallBox, SmartMessageBox } from "../../common/utils/functions";

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import MemberSearchForm from '../Member/MemberSearchForm';

import 'react-widgets/dist/css/react-widgets.css';
import 'font-awesome/css/font-awesome.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { faChartBar } from '@fortawesome/free-solid-svg-icons'

class TopNav extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formFields: {
                lName: '',
                fName: '',
                ss_no: '',
                class: ''
            }
        }

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.handleLogout = this.handleLogout.bind(this);
        this.handleNavigation = this.handleNavigation.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.state.showDropdown && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.toggleDropdown();
        }
    }

    toggleDropdown = () => {
        console.log("toggle");
        if (this.state.showDropdown) {
            this.setState({
                showDropdown: false,
                formFields: {
                    lName: '',
                    fName: '',
                    ss_no: '',
                    clock: ''
                }
            });
        } else {
            this.setState({ showDropdown: true });
        }
    }

    handleLogout(event) {
        SmartMessageBox(
            {
                title: "You are about to logout.",
                content:
                    "Are you sure you would like to proceed?",
                buttons: "[No][Yes]"
            },
            (ButtonPressed) => {
                if (ButtonPressed === "Yes") {
                    event.preventDefault();
                    let path = '';
                    path = '/';

                    Globals.isDirtyPage = false;
                    Globals.userInfo = {};
                    this.props.history.replace(path);
                }
                if (ButtonPressed === "No") {
                    return 0;
                }
            }
        );
    }

    handleNavigation(event) {
        event.preventDefault();
        let path = '';
        switch (event.currentTarget.id) {
            case 'members':
                path = '/members';
                this.props.history.replace(path);
                break;
            case 'memberDues':
                path = '/members-dues';
                this.props.history.replace(path);
                break;
            case 'tab4a':
                $(".listHeader").removeClass("active open");
                $("#tab3List").toggleClass("active open");
                path = '/providers';
                this.props.history.replace(path);
                break;
            default:
                break;
        }

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        console.log(name);

        this.setState({ formFields: { ...this.state.formFields, [name]: value } }, () => console.log(this.state.formFields));
    }

    handleDateChange = (name, date) => {
        this.setState({ formFields: { ...this.state.formFields, [name]: date } }, () => {
            if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                this.isDirty = true;  // dirty
            }

        });
    }

    handleDropdownChange = (value) => {
        this.setState({
            value
        }, () => console.log(this.state.value));
    }

    handleSubmit(event) {
        event.preventDefault();

        Globals.loadingMembers = true;

        let postdata = {
            memberModel: {
                fName: this.state.formFields.fName,
                lName: this.state.formFields.lName,
                ss_no: this.state.formFields.ss_no,
                clock: this.state.formFields.clock,
            },
        }
        Globals.members = {};

        $(".search-menu").removeClass("show");

        this.setState({
            formFields: {
                fName: '',
                lName: '',
                ss_no: '',
                clock: ''
            }
        });
        console.log(postdata);
        Axios.post(`/api/GetMembersByLookup`, postdata
        ).then(response => {
            Globals.members = response.data;
            Globals.loadingMembers = false;
            console.log(response.data);
            const path = {
                pathname: '/members'
            };

            let currentRoute = this.props.history.location.pathname;

            if (currentRoute === '/members') {
                this.props.history.replace({
                    pathname: '/members',
                    state: { members: response.data }
                });
            }
            else {
                this.props.history.push({
                    pathname: '/members',
                    state: { members: response.data }
                });
            }

        }).catch(error => {
            console.log(error);
        });
    }

    render() {
        console.log(this.state.formFields);

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                if (e.target.tagName === 'TD' || e.target.tagName === 'TR') {

                    let postdata = {
                        uniqueID: row.memberModel.ss_no
                    };

                    Axios.post(`/api/GetMemberInformationBySSN`, postdata
                    ).then(response => {
                        this.setState({
                            selectedMember: response.data
                        }, () => this.selectMember(this.state.selectedMember));

                    }).catch(error => {
                        console.log(error);
                    });
                }
                else if (e.target.tagName === 'BUTTON' || e.target.tagName === 'I') {
                    let postdata = {
                        uniqueID: row.memberModel.ss_no
                    };

                    Axios.post(`/api/GetMemberInformationBySSN`, postdata
                    ).then(response => {
                        this.setState({
                            selectedMember: response.data
                        });

                    }).catch(error => {
                        console.log(error);
                    });
                }
            }
        };

        const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
            <span href=""
                ref={ref}
                onClick={e => {
                    e.preventDefault();
                    onClick(e);
                }} className="inline-button" id="dropdownMenuOffset" data-offset="0,10">

                <FaAngleDown />

            </span>
        ));
        return (
            <header className="page-header" role="banner">
                {/* we need this logo when user switches to nav-function-top */}
                <div className="page-logo">
                    <img src={ribbonLogo} alt="Local 777 Logo" className="mx-auto" style={{ height: '100px' }} />&nbsp;&nbsp;&nbsp;<span className="page-logo-text local777TextA">Plumbers & Pipefitters Local 777 Member Dues</span>
                </div>
                <div>

                </div>
                <div className=" d-flex mr-auto">
                    {/* activate app search icon (mobile) */}
                    <div className="hidden-sm-up">
                        <span className="header-icon mimicA" data-action="toggle" data-class="mobile-search-on" data-focus="search-field" title="Search">
                            <i className="fal fa-search" />
                        </span>
                    </div>

                </div>
                <Fragment>
                    <MemberSearchForm className="float-right" {...this.props} from='nav' />
                </Fragment>

                <div className="d-flex ml-auto">
                    
                    <span className="page-logo-text local777TextA">{Globals.userInfo.fullName}</span>
                    
                </div>

                <div>
                    <span className="header-icon mimicA top-nav-item" onClick={this.handleLogout} title={'Logout, ' + Globals.userInfo.fullName}>
                        <i className="far fa-sign-out-alt logoutIcon text-icon"></i>
                    </span>
                </div>


                {/* DOC: nav menu layout change shortcut */}
                <div className="hidden-md-down dropdown-icon-menu position-relative">
                    <span className="header-btn btn js-waves-off" data-action="toggle" data-class="nav-function-hidden" title="Hide Navigation">
                        <i className="ni ni-menu" />
                    </span>
                    <ul>
                        <li>
                            <span className="btn js-waves-off" data-action="toggle" data-class="nav-function-minify" title="Minify Navigation">
                                <i className="ni ni-minify-nav" />
                            </span>
                        </li>
                        <li>
                            <span className="btn js-waves-off" data-action="toggle" data-class="nav-function-fixed" title="Lock Navigation">
                                <i className="ni ni-lock-nav" />
                            </span>
                        </li>
                    </ul>
                </div>
                {/* DOC: mobile button appears during mobile width */}
                <div className="hidden-lg-up">
                    <span onClick={this.props.showLeftNav} className="header-btn btn press-scale-down" data-action="toggle" data-class="mobile-nav-on">
                        <i className="ni ni-menu" />
                    </span>
                </div>
                
                {/* <ExamSearch showPane={this.state.showExamSearch} from='general' hidePane={() => this.setState({ showExamSearch: false })} /> */}
            </header>
        );
    }
}

export default withRouter(TopNav);